<!--  -->
<template>
  <div class="div-main">
    <div class="box">
      <p class="box-title">{{ title }}</p>
      <div class="box-nav">
        <div :class="{ 'active': selectIndex == 0 }" @click="selectItem(0)">基本信息</div>
        <div :class="{ 'active': selectIndex == 1 }" @click="selectItem(1)">高级配置</div>

      </div>

      <keep-alive>
        <router-view @update="updateSelect" @updateData="updateData"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import common from "common/js/common";
export default {
  data () {
    return {
      title: '开始创建数字员工',
      selectIndex: 0,
      eid:null,
      
    }
  },
  methods: {
    selectItem (index) {
    

      if (index == 0) {
        this.selectIndex = index;
        if (common.isStringEmpty(this.eid)) {
      this.$router.replace({ path: '/wkr/create'})
    } else { 
      this.$router.replace({ path: '/wkr/create?eid='+this.eid })
        }
    
      }
      else if (index == 1) {

        if (common.isStringEmpty(this.eid)) {

          this.$message({
            message: '创建员工后才能进行高级配置，请先创建员工',
          type: 'warning'
          });

        } else { 
          this.selectIndex = index;
          this.$router.replace({ path: '/wkr/advanced?eid='+this.eid})
        }
      }
    }
    ,
    updateSelect () {


      this.selectIndex = 0;
      sessionStorage.setItem('current_name', this.selectIndex)
      if (sessionStorage.getItem('empId') == null) {
      } else {
        this.empId = sessionStorage.getItem('empId');
      }
      this.$router.replace({ path: '/pmt/fileup?eid=' + this.$Base64.encode(this.empId + "") })

    },
    updateData (empId1, oid1) {
      this.empId = empId1;
      this.oid = oid1;
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    if (common.isStringEmpty(this.$route.query.eid)) {
      this.title = "开始创建数字员工";
     
    } else { 
      this.title = "编辑该数字员工";
      this.eid = this.$route.query.eid;
    }
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    console.log("-------------");
    if (!common.isStringEmpty(this.$route.query.flag)) {
      this.selectIndex = 1;
      this.$router.replace({ path: '/wkr/advanced?eid='+this.eid })
    } else { 
      this.selectIndex = 0;
      if (common.isStringEmpty(this.$route.query.eid)) {
      this.$router.replace({ path: '/wkr/create'})
    } else { 
      this.$router.replace({ path: '/wkr/create?eid='+this.eid })
    }
    }
    
    
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.div-main {
  padding-top: 20px;
  padding-bottom: 20px;
}

.box {

  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0px auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(9, 56, 128, 0.05);
  border-radius: 12px 12px 12px 12px;


}

.box-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  padding-top: 40px;
  line-height: 34px;
  margin-left: 60px;
}

.box-nav {
  display: flex;
  margin-top: 6px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECF1F8;
  margin-left: 60px;
}

.box-nav div {
  height: 56px;
  position: relative;
  line-height: 56px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 40px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  cursor: pointer;
}

.box-nav .active {
  position: relative;
  /* display: block !important; */
  color: #000000 !important;
  font-weight: 700;
}

.box-nav .active:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #226CFF;
}</style>